<template>
  <ul class="menu-nav">
    <router-link
      v-for="(menu, idx) in items"
      :key="idx"
      :to="menu.to"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        v-if="menu.show"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i :class="menu.icon"></i>
          <span class="menu-text">{{ menu.label }}</span>
        </a>
      </li>
    </router-link>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
      <a href="#" class="menu-link" @click="onLogout">
        <i class="menu-icon flaticon2-cross"></i>
        <span class="menu-text">Sair</span>
      </a>
    </li>
  </ul>
</template>

<script>
import { LOGOUT } from '@/core/services/store/auth.module'

export default {
  name: 'KTMenu',

  data: () => ({
    menus: [
      {
        to: '/dashboard',
        label: 'Início',
        icon: 'menu-icon flaticon2-menu-4',
        show: true
      },
      {
        to: '/salesmen',
        label: 'Vendedores',
        icon: 'menu-icon flaticon2-group',
        show: true
      },
      {
        to: '/categories',
        label: 'Produtos',
        icon: 'menu-icon flaticon2-box',
        show: true
      },
      {
        to: '/laboratories',
        label: 'Laboratórios',
        icon: 'menu-icon flaticon2-supermarket',
        show: true
      },
      {
        to: '/stores',
        label: 'Óticas',
        icon: 'menu-icon flaticon2-supermarket',
        show: true
      },
      {
        to: '/faq',
        label: 'Faq',
        icon: 'menu-icon flaticon2-help',
        show: true
      },
      {
        to: '/certificates',
        label: 'Certificados',
        icon: 'menu-icon flaticon2-correct',
        show: true
      },
      {
        to: '/me',
        label: 'Meu perfil',
        icon: 'menu-icon flaticon2-user',
        show: true
      }
    ]
  }),

  computed: {
    user() {
      return this.$store.state.auth.user
    },

    items() {
      return this.menus.map((item) => {
        return {
          to: item.to,
          label: item.label,
          icon: item.icon,
          show: this.checkPermission(item)
        }
      })
    }
  },

  methods: {
    checkPermission(item) {
      if (item.to === '/salesmen' && !this.user.is_store) {
        return false
      }

      if (
        (item.to === '/stores' && this.user.is_store) ||
        (item.to === '/stores' && this.user.is_salesman)
      ) {
        return false
      }

      if (
        (item.to === '/categories' && this.user.is_store) ||
        (item.to === '/categories' && this.user.is_salesman)
      ) {
        return false
      }

      if (
        (item.to === '/certificates' && this.user.is_store) ||
        (item.to === '/certificates' && this.user.is_salesman)
      ) {
        return false
      }

      if (
        (item.to === '/faq' && this.user.is_admin) ||
        (item.to === '/faq' && this.user.is_lab)
      ) {
        return false
      }

      if (
        (item.to === '/laboratories' && this.user.is_store) ||
        (item.to === '/laboratories' && this.user.is_salesman) ||
        (item.to === '/laboratories' && this.user.is_lab)
      ) {
        return false
      }

      return true
    },

    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    },

    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }))
    }
  }
}
</script>
