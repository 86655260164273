<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Oi,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ getFullName }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          <i class="ki ki-close icon-xs text-muted"></i>
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
          <small class="text-muted font-size-sm ml-2">12 messages</small>
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <div class="d-flex align-items-center mt-5">
        <div class="symbol symbol-100 mr-5">
          <i class="symbol-badge bg-success"></i>
        </div>
        <div class="d-flex flex-column">
          <router-link
            to="/custom-pages/profile"
            class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
          >
            {{ getFullName }}
          </router-link>

          <button class="btn btn-light-primary btn-bold" @click="onLogout">
            Sair
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LOGOUT } from '@/core/services/store/auth.module'
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js'
import KTOffcanvas from '@/assets/js/components/offcanvas.js'

export default {
  name: 'KTQuickUser',

  computed: {
    ...mapGetters(['currentUser']),

    getFullName() {
      return this.currentUser.name
    }
  },

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }))
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide()
    }
  },

  mounted() {
    KTLayoutQuickUser.init(this.$refs['kt_quick_user'])
  }
}
</script>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>
