<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <a
      href="#"
      style="color: #fff"
      @click.prevent.stop="$router.push('/dashboard')"
    >
      OptiCert
    </a>
    <div class="d-flex align-items-center">
      <button
        class="botao"
        @click.prevent.stop="$router.go(-1)"
        v-if="$route.path !== '/dashboard'"
      >
        <i class="menu-icon flaticon2-back"></i>
      </button>
      <Search />
      <SearchCertificate />
      <button
        v-if="asideEnabled"
        class="btn p-0 burger-icon burger-icon-left"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KTLayoutHeaderTopbar from '@/assets/js/layout/base/header-topbar.js'
import Search from '@/components/Search'

export default {
  name: 'KTHeaderMobile',

  components: { Search },

  mounted() {
    KTLayoutHeaderTopbar.init(this.$refs['kt_header_mobile_topbar_toggle'])
  },

  computed: {
    ...mapGetters(['layoutConfig', 'getClasses']),

    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig('self.logo.dark')
    },

    headerClasses() {
      const classes = this.getClasses('header_mobile')
      if (typeof classes !== 'undefined') {
        return classes.join(' ')
      }
      return null
    },

    asideEnabled() {
      return !!this.layoutConfig('aside.self.display')
    }
  }
}
</script>

<style lang="css" scoped>
.botao {
  border: 1px solid #1a1a27;
  background: #1a1a27;
  margin-right: 20px;
}
</style>
