var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.items),function(menu,idx){return _c('router-link',{key:idx,attrs:{"to":menu.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [(menu.show)?_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{class:menu.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.label))])])]):_vm._e()]}}],null,true)})}),_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"#"},on:{"click":_vm.onLogout}},[_c('i',{staticClass:"menu-icon flaticon2-cross"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sair")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }